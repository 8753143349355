import { render, staticRenderFns } from "./SuccessModal.vue?vue&type=template&id=5b95e49a&scoped=true"
import script from "./SuccessModal.vue?vue&type=script&lang=js"
export * from "./SuccessModal.vue?vue&type=script&lang=js"
import style0 from "./SuccessModal.vue?vue&type=style&index=0&id=5b95e49a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b95e49a",
  null
  
)

export default component.exports