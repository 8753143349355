var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column bgWalletBlockDark pa-6 rounded"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('ens.register.domain-name'))+":")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.name))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('ens.register.term'))+":")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.duration === 1 ? _vm.$tc('ens.commit.year', 1) : _vm.$tc('ens.commit.year', 2, { duration: _vm.duration })))])]),(!_vm.committed && !_vm.loadingCommit && _vm.waitingForReg)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v("Estimated Fee:")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.commitFeeInEth)+" ETH ("+_vm._s(_vm.commitFeeUsd)+") ")])]):_vm._e(),(!_vm.committed && _vm.loadingCommit && _vm.waitingForReg)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v("Commitment Cost:")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.commitFeeInEth)+" ETH ("+_vm._s(_vm.commitFeeUsd)+") ")])]):_vm._e(),(_vm.committed && !_vm.loadingCommit && !_vm.waitingForReg)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v("Commitment Cost:")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.commitFeeInEth)+" ETH ("+_vm._s(_vm.commitFeeUsd)+") ")])]):_vm._e(),(!_vm.committed && _vm.loadingCommit && _vm.waitingForReg)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" Generating registration cost. Please wait... ")])]):_vm._e(),(
        !_vm.committed && !_vm.loadingCommit && !_vm.waitingForReg && !_vm.noFundsForRegFees
      )?_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v("Registration Cost:")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.totalCost)+" ETH ("+_vm._s(_vm.totalCostUsd)+") ")])]):_vm._e(),(
        !_vm.committed && !_vm.loadingCommit && !_vm.waitingForReg && _vm.noFundsForRegFees
      )?_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v("Registration Cost:")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" Not enough funds to complete registration ")])]):_vm._e()]),(_vm.minimumAge || _vm.canRegister)?_c('div',{staticClass:"timer-container d-flex flex-column align-center justify-center mt-4"},[_c('mew-icon',{staticClass:"canRegister ? 'disabled' : ''",attrs:{"icon-name":"clock","img-height":80}}),_c('span',{staticClass:"ticket-subtitle greenPrimary--text"},[_vm._v(_vm._s(_vm.ticker))]),(!_vm.canRegister)?_c('div',{staticClass:"d-flex flex-column mt-5 justify-center align-center"},[_c('span',{staticClass:"mew-heading-2"},[_vm._v(_vm._s(_vm.$t('ens.hang-on')))]),_c('span',{staticClass:"mt-3 desc-container"},[_vm._v(_vm._s(_vm.$t('ens.hang-on-committing')))])]):_vm._e(),(_vm.canRegister)?_c('div',{staticClass:"d-flex flex-column mt-5 justify-center align-center"},[_c('span',{staticClass:"mew-heading-2"},[_vm._v(_vm._s(_vm.$t('ens.register.complete-reg')))]),_c('span',{staticClass:"mt-3 desc-container"},[_vm._v(_vm._s(_vm.$t('ens.register.complete-reg-desc')))])]):_vm._e()],1):_vm._e(),(_vm.notEnoughFunds || _vm.noFundsForRegFees)?_c('div',[_c('span',{staticClass:"balance-error d-flex mt-5 justify-center align-center"},[_vm._v(" Not enough balance: "),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.network.type.canBuy),expression:"network.type.canBuy"}],staticClass:"link",attrs:{"target":"_blank"},on:{"click":function () {
            _vm.openBuySell('ENSRegister');
          }}},[_c('u',[_vm._v("Buy More Eth")])])])]):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-6"},[_c('mew-button',{attrs:{"disabled":_vm.loadingCommit ||
        _vm.ticker !== '00:00' ||
        _vm.notEnoughFunds ||
        _vm.noFundsForRegFees ||
        _vm.loadingReg,"loading":_vm.loadingReg,"title":_vm.canRegister
          ? _vm.$t('ens.register.name')
          : _vm.$t('ens.register.create-commitment'),"btn-size":"xlarge"},nativeOn:{"click":function($event){!_vm.canRegister ? _vm.commit() : _vm.register(_vm.duration)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }